import { useEffect } from 'react'
import { useStore } from './store'
import { mergeInvites, resetInvites, deleteInviteID } from '../reducers/actions'
import logErr from '../lib/err'

const supressLogging = process.env.NEXT_PUBLIC_SUPPRESS_HOOK_LOGGING === 'true'

export const useSubInvites = () => {
  const [{ auth, me }, dispatch] = useStore()

  const meLoaded = me?.loaded
  const uid = meLoaded && me.user.uid

  const onSnap = (qSnap) => {
    try {
      const changes = qSnap.docChanges()
      const nChanges = changes.length
      const invs = {}
      for (let i = 0; i < nChanges; i++) {
        const { doc, type } = changes[i]
        switch (type) {
          case 'added':
          case 'modified':
            invs[doc.id] = doc.data()
            break
          case 'removed':
            dispatch(deleteInviteID(doc.id))
            break
        }
      }
      if (Object.entries(invs).length > 0) dispatch(mergeInvites(invs))
    } catch (err) {
      logErr(err, 'FAILED in Invite watch handler')
      dispatch(resetInvites())
    }
  }

  useEffect(() => {
    if (meLoaded) {
      !supressLogging && console.log('RUNNING INVITES EFFECT')

      try {
        !supressLogging && console.log('SUB invites')
        const unSubscribe = auth.firebase
          .firestore()
          .collection('invites')
          .where('uid', '==', uid)
          .where('show', '==', true)
          .onSnapshot(onSnap, (err) => {
            logErr(err, 'subscribe invitees failed')
            dispatch(resetInvites())
          })
        return () => {
          !supressLogging && console.log('UNSUB invites')
          unSubscribe()
        }
      } catch (err) {
        logErr(err, 'FAILED in Invite sub')
        dispatch(resetInvites())
      }
    } else {
      dispatch(resetInvites())
    }
  }, [meLoaded])
  return null
}
export default useSubInvites
