import { ME_USER_CHANGE, ME_RESET, ME_USER_MERGE } from './actions'

export const initialMe = {
  user: null,
  // loading: false,
  loaded: false,
}

export const meReducer = (me, action) => {
  switch (action.type) {
    case ME_USER_CHANGE:
      return {
        ...me,
        user: action.user,
        loaded: action.user ? true : false,
      }
    case ME_USER_MERGE:
      return { ...me, user: { ...me.user, ...action.user } }
    case ME_RESET:
      return initialMe
    default:
      return me
  }
}
