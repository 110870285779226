import { useState, useEffect } from 'react'

const getOrientation = () => {
  if (typeof window !== 'undefined' && window?.screen?.orientation?.type) {
    return window.screen.orientation.type
  }
  // iOS fallback
  if (typeof window !== 'undefined' && window.orientation) {
    if (window.orientation === 90 || window.orientation === -90) {
      return 'landscape-primary'
    } else {
      return 'portrait-primary'
    }
  }
  // server-side etc fallback
  return null
}

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation())

  const updateOrientation = () => {
    setOrientation(getOrientation())
  }

  useEffect(() => {
    if (typeof window === 'undefined') {
      return null
    }
    window.addEventListener('orientationchange', updateOrientation)
    return () => {
      window.removeEventListener('orientationchange', updateOrientation)
    }
  }, [typeof window])

  return orientation
}

export default useScreenOrientation
