import { useEffect } from 'react'
import { useStore } from './store'
import { mergePosses, resetPosses, deletePosseID, loadingPosses } from '../reducers/actions'
import logErr from '../lib/err'

const supressLogging = process.env.NEXT_PUBLIC_SUPPRESS_HOOK_LOGGING === 'true'

export const useSubPosses = (campaign) => {
  const [{ auth, me }, dispatch] = useStore()

  const meLoaded = me?.loaded
  const myPosses = me?.user?.posses?.vote2020
  const possesCount = (me.loaded && myPosses && Object.keys(myPosses).length) || 0

  useEffect(() => {
    if (meLoaded && me.user.posses) {
      const ps = me.user.posses[campaign] || {}
      const ids = Object.values(ps)
      !supressLogging && console.log('RUNNING POSSES EFFECT', ids)
      const idsLength = ids.length
      if (idsLength == 0) {
        dispatch(resetPosses())
        return
      }

      dispatch(loadingPosses(true))

      const onSnap = (qSnap) => {
        try {
          const changes = qSnap.docChanges()
          const nChanges = changes.length
          const posses = {}
          for (let i = 0; i < nChanges; i++) {
            const { doc, type } = changes[i]
            switch (type) {
              case 'added':
              case 'modified':
                posses[doc.id] = doc.data()
                break
              case 'removed':
                dispatch(deletePosseID(doc.id))
                break
            }
          }
          if (Object.entries(posses).length > 0) dispatch(mergePosses(posses))
          dispatch(loadingPosses(false))
        } catch (err) {
          console.error('FAILED in Posse watch handler')
          dispatch(resetPosses())
          dispatch(loadingPosses(false))
        }
      }

      try {
        !supressLogging && console.log('SUB posses for: ', ids)
        const idSub = auth.firebase
          .firestore()
          .collection('posses')
          .where('campaign', '==', campaign)
          .where('id', 'in', ids)
          .onSnapshot(onSnap, (err) => {
            logErr(err, 'subscribe posses failed')
            dispatch(resetPosses())
          })
        return () => {
          !supressLogging && console.log('UNSUB posses')
          idSub()
        }
      } catch (err) {
        console.error('FAILED in Posse sub')
        dispatch(resetPosses())
      }
    } else {
      dispatch(resetPosses())
    }
  }, [meLoaded, possesCount, campaign])
  return null
}
export default useSubPosses
