import { PROFILES_MERGE, PROFILES_REMOVE_ID, PROFILES_RESET } from './actions'

export const initialProfiles = null

export const profilesReducer = (profiles, action) => {
  const st = { ...profiles }
  switch (action.type) {
    case PROFILES_MERGE:
      return { ...profiles, ...action.profiles }
    case PROFILES_REMOVE_ID:
      delete st[action.id]
      if (Object.keys(st).length > 0) return st
      else return initialProfiles
    case PROFILES_RESET:
      return initialProfiles
    default:
      return profiles
  }
}
