import { INVITES_MERGE, INVITES_REMOVE_ID, INVITES_RESET } from './actions'

export const initialInvites = null

export const invitesReducer = (state, action) => {
  const st = { ...state }
  switch (action.type) {
    case INVITES_MERGE:
      return { ...st, ...action.invites }
    case INVITES_REMOVE_ID:
      delete st[action.id]
      if (Object.keys(st).length > 0) return st
      else return initialInvites
    case INVITES_RESET:
      return initialInvites
    default:
      return state
  }
}
