import { POSSES_MERGE, POSSES_REMOVE_ID, POSSES_RESET } from './actions'

export const initialPosses = null

export const possesReducer = (posses, action) => {
  const st = { ...posses }
  switch (action.type) {
    case POSSES_MERGE:
      return { ...st, ...action.posses }
    case POSSES_REMOVE_ID:
      delete st[action.id]
      if (Object.keys(st).length > 0) return st
      else return initialPosses
    case POSSES_RESET:
      return initialPosses
    default:
      return posses
  }
}
