import { useMemo } from 'react'
import { useStore } from './store'
import { FUNCTIONS_URL_DEV } from '../globals'

export const useFunctions = () => {
  const [{ auth }, dispatch] = useStore()

  const fns = useMemo(() => {
    if (auth.firebase) {
      const fns = auth.firebase.functions()
      return fns
    }
  }, [auth.firebase])

  return fns
}

export default useFunctions
