import firebase from 'firebase/app'
import { useState } from 'react'

export const useCloudFn2 = (fnName, options) => {
  const [pending, setPending] = useState(false)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)
  const fnCall = firebase.functions().httpsCallable(fnName, options)
  const fn = async (data) => {
    setPending(true)
    setResult(null)
    setError(null)
    try {
      let res = await fnCall(data)
      if (res.data) {
        res = res.data
        setResult(res)
      } else if (res.error) {
        setError(res.error)
      }
      return res
    } catch (err) {
      setError(err)
      return err
    } finally {
      setPending(false)
    }
  }
  return [{ pending, result, error }, fn]
}
export default useCloudFn2
