import {
  UI_CLOSE_HAMBURGER,
  UI_OPEN_HAMBURGER,
  UI_TOGGLE_HAMBURGER,
  UI_SET_AMAZON_SECTION,
  UI_GOTO_FAQ,
  UI_SHOW_PLEDGE_REMINDER,
  UI_PLEDGE_REMINDER_DISMISSED,
  UI_GOTO_AFTER_LOGIN,
  UI_APP_ERROR,
  UI_LOADING_POSSES,
  UI_LOADING_PROFILES,
  UI_ENABLE_LOGIN_REDIRECT,
  UI_STATE,
} from './actions'

export const initialUI = {
  hamburgerMenuOpen: false,
  amazonSection: null,
  faq: false,
  showPledgeReminder: false,
  pledgeReminderAlreadyDismissed: false,
  appError: null,
  gotoAfterLogin: null,
  loadingPosses: false,
  loadingProfiles: false,
  enableLoginRedirect: true,
  state: {},
}

export const uiReducer = (ui, action) => {
  switch (action.type) {
    case UI_CLOSE_HAMBURGER:
      return { ...ui, hamburgerMenuOpen: false }

    case UI_OPEN_HAMBURGER:
      return { ...ui, hamburgerMenuOpen: true }
    case UI_TOGGLE_HAMBURGER:
      return { ...ui, hamburgerMenuOpen: !ui.hamburgerMenuOpen }
    case UI_SET_AMAZON_SECTION:
      return { ...ui, amazonSection: action.section }
    case UI_GOTO_FAQ:
      return { ...ui, faq: action.faq }
    case UI_SHOW_PLEDGE_REMINDER:
      return {
        ...ui,
        showPledgeReminder: true,
        unpledgedCampaign: action.campaign,
      }
    case UI_PLEDGE_REMINDER_DISMISSED:
      return {
        ...ui,
        showPledgeReminder: false,
        pledgeReminderAlreadyDismissed: true,
        unpledgedCampaign: null,
      }
    case UI_GOTO_AFTER_LOGIN:
      return {
        ...ui,
        gotoAfterLogin: action.path,
      }
    case UI_APP_ERROR:
      return {
        ...ui,
        appError: action.error,
      }
    case UI_LOADING_POSSES:
      return {
        ...ui,
        loadingPosses: action.loadingPosses,
      }
    case UI_LOADING_PROFILES:
      return {
        ...ui,
        loadingProfiles: action.loadingProfiles,
      }
    case UI_STATE:
      return {
        ...ui,
        state: { ...ui.state, ...action.state },
      }
    case UI_ENABLE_LOGIN_REDIRECT:
      return {
        ...ui,
        enableLoginRedirect: action.enable,
      }
    default:
      return ui
  }
}
