import { useState } from 'react'

export default function useInviteForm(initialMode = false) {
  const [isInviteFormOpen, setInviteFormOpen] = useState(initialMode)
  const [inviteData, setInviteData] = useState({})
  const showForm = (show, inviteData) => {
    //console.log(show)
    if (inviteData) {
      setInviteData(inviteData)
      setInviteFormOpen(show)
    } else {
      setInviteFormOpen(show)
      setInviteData({})
    }
  }
  return [isInviteFormOpen, showForm, inviteData]
}
