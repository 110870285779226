import { useState, useEffect, useCallback } from 'react'

const supressLogging = process.env.NEXT_PUBLIC_SUPPRESS_HOOK_LOGGING === 'true'

export const useAsync = (asyncFunction, context = null) => {
  const [pending, setPending] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  // console.log("SETUP useAsync");

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const callback = useCallback(() => {
    !supressLogging && console.log('CALLBACK useAsync', data, context)
    setPending(true)
    setValue(null)
    setError(null)
    setData(null)
    return asyncFunction(data, context)
      .then((response) => setValue(response))
      .catch((error) => setError(error))
      .finally(() => setPending(false))
  }, [asyncFunction, data])

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    //console.log("EFFECT useAsync", data);
    if (data) {
      //console.log("CALLING useAsync", data);
      callback()
    }
  }, [callback, data])

  const execute = (data) => {
    //console.log("EXECUTE useAsync", data);
    setData(data)
  }

  return [{ pending, value, error }, execute]
}

export default useAsync
