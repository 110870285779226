import firebase from 'firebase/app'
import { useStore } from './index'
import logErr, { logMsg } from '../lib/err'
import { AUTH_STATE, AUTH_STATE_CHANGE, AUTH_USER_CHANGE } from '../reducers/actions'

const fn = async (auth, dispatch, authToken) => {
  dispatch({ type: AUTH_STATE_CHANGE, authState: AUTH_STATE.AUTHING, error: null })
  console.log('AUTHING ...')
  let tryCount = 0
  while (tryCount < 3) {
    const tokenTimeout = setTimeout(
      (auth) => {
        const err = new Error('auth timeout: ' + auth.authState)
        // FIXME display to user an error screen?
        logErr(err)
      },
      10000,
      auth
    )
    try {
      const UserCredential = await firebase.auth().signInWithCustomToken(authToken)
      clearTimeout(tokenTimeout)
      console.log('AUTH RESPONSE', UserCredential.user)
      return Promise.resolve()
    } catch (error) {
      clearTimeout(tokenTimeout)
      tryCount++
      if (tryCount < 3) {
        logMsg(`auth try ${tryCount} failed: ` + error.code)
      } else {
        logErr(error, 'auth failed')
        dispatch({ type: AUTH_STATE_CHANGE, authState: AUTH_STATE.ERROR, error })
        dispatch({ type: AUTH_USER_CHANGE, user: null })
        // reject if it fails
        return Promise.reject(new Error('auth failed'))
      }
    }
  }
}
export default function useAuthWithToken() {
  const [{ auth }, dispatch] = useStore()
  return (authToken) => {
    return fn(auth, dispatch, authToken)
  }
}
