import useAsync from './useAsync'
import useFunctions from './useFunctions'

export const useCloudFn = (fnName) => {
  const fns = useFunctions()
  const fnCall = fns && fns.httpsCallable(fnName)
  const [state, exec] = useAsync(fnCall)
  if (state && state.value) {
    if (state.value.data) {
      if (state.value.data.err) state.error = state.value.data.err
      else if (state.value.error) state.error = state.value.error
      else state.value = state.value.data
    }
  }
  return [state, exec]
}
export default useCloudFn
