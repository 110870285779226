import { useEffect } from 'react'
import { useStore } from './store'
import { mergeProfiles, resetProfiles, deleteProfileID, loadingProfiles } from '../reducers/actions'
import logErr from '../lib/err'

const supressLogging = process.env.NEXT_PUBLIC_SUPPRESS_HOOK_LOGGING === 'true'

export const useSubProfiles = () => {
  const [{ auth, me, posses, profiles, ui }, dispatch] = useStore()

  const possesCount = posses && Object.keys(posses).length

  useEffect(() => {
    if (possesCount > 0 && auth.user && auth.user.uid) {
      const uids = []
      const myUID = auth.user.uid
      Object.values(posses).forEach((posse) => {
        const isMyPosse = myUID === posse.captain
        const memberUIDs = Object.keys(posse.members)
        const inviteeUIDs = isMyPosse && posse.invitees && Object.keys(posse.invitees)

        if (memberUIDs.length > 0) {
          memberUIDs.forEach((memberUID) => {
            if (!uids.includes(memberUID) && memberUID !== myUID) uids.push(memberUID)
          })
        }
        if (inviteeUIDs && inviteeUIDs.length > 0) {
          inviteeUIDs.forEach((invUID) => {
            if (!uids.includes(invUID)) uids.push(invUID)
          })
        }
        !supressLogging && console.log('loading profile UIDs from posse', posse.id, uids, myUID)
      })

      !supressLogging && console.log('RUNNING PROFILES EFFECT', uids)
      const idsLength = uids.length
      if (idsLength == 0) return

      dispatch(loadingProfiles(true))

      const onSnap = (qSnap) => {
        try {
          const changes = qSnap.docChanges()
          const nChanges = changes.length
          const profs = {}
          for (let i = 0; i < nChanges; i++) {
            const { doc, type } = changes[i]
            switch (type) {
              case 'added':
              case 'modified':
                profs[doc.id] = doc.data()
                break
              case 'removed':
                dispatch(deleteProfileID(doc.id))
                break
            }
          }
          if (Object.entries(profs).length > 0) dispatch(mergeProfiles(profs))
          dispatch(loadingProfiles(false))
        } catch (err) {
          console.error('FAILED in Profiles watch handler')
          dispatch(resetProfiles())
          dispatch(loadingProfiles(false))
        }
      }

      try {
        // split into groups of 10
        const numUIDs = uids.length
        const numArrs = Math.ceil(numUIDs / 10)
        const unsubs = []
        uids.sort((a, b) => a - b)

        for (let i = 0; i < numArrs; i++) {
          const start = i * 10
          const until = (i + 1) * 10
          const slice = uids.slice(start, until > numUIDs ? numUIDs : until)
          !supressLogging && console.log('SUB profiles: ', slice)
          unsubs[i] = auth.firebase
            .firestore()
            .collection('profiles')
            .where('uid', 'in', slice)
            .onSnapshot(onSnap, (err) => {
              logErr(err, 'subscribe profiles failed')
              dispatch(resetProfiles())
            })
        }
        return () => {
          try {
            !supressLogging && console.log('UNSUB profiles')
            const _unsubs = unsubs
            _unsubs.forEach((unsub) => {
              unsub()
            })
          } catch (err) {
            logErr(err)
          }
        }
      } catch (err) {
        dispatch(resetProfiles())
        logErr(err, 'FAILED in Profiles sub')
      }
    } else {
      dispatch(resetProfiles())
    }
  }, [me.loaded, posses])
  return [profiles, ui.loadingProfiles]
}
export default useSubProfiles
