import logErr from '../lib/err'
import { AUTH_STATE, AUTH_STATE_CHANGE, AUTH_USER_CHANGE } from './actions'

export const initialAuth = {
  authState: AUTH_STATE.INIT,
  user: null,
  firebase: null,
  isAuthing: true,
  isAuthed: false,
  initted: false,
}

export const authReducer = (auth, action) => {
  let state

  switch (action.type) {
    case AUTH_STATE_CHANGE:
      state = { ...auth, authState: action.authState, error: action.error }
      if (action.authState === AUTH_STATE.INITTED) {
        state.firebase = action.firebase
        state.initted = true
      }
      // if (action.error) {
      //   logErr(action.error)
      // }
      break
    case AUTH_USER_CHANGE:
      state = { ...auth, user: action.user }
      break
    default:
      // if we did nothing, then return the unchanged original state
      return auth
  }

  state.isAuthing =
    (state.authState === AUTH_STATE.INIT ||
      state.authState === AUTH_STATE.INITTED ||
      state.authState === AUTH_STATE.LOADING ||
      state.authState === AUTH_STATE.AUTHING) &&
    state.authState !== AUTH_STATE.NONE &&
    state.authState !== AUTH_STATE.AUTHED &&
    state.authState !== AUTH_STATE.ERROR
  state.isAuthed = state.authState === AUTH_STATE.AUTHED

  // if we did stuff then return the changed state
  return state
}
